<template>
  <div class="your-search-form">
    <div class="form-container">
      <div v-if="activeTab === TAB_ID_INDEXED_RECORDS" class="indexed-records-form form">
        <div class="filter">
          <label class="input-label">First Name</label>
          <input
            v-capitalize
            :value="simpleFormData.first_name"
            class="input"
            @input="onInputChange('first_name', $event)"
            @keyup.enter="onInputEnter"
          />
        </div>
        <div class="filter">
          <label class="input-label">Last Name</label>
          <input
            v-capitalize
            :value="simpleFormData.surname"
            class="input"
            @input="onInputChange('surname', $event)"
            @keyup.enter="onInputEnter"
          />
        </div>
        <place-input
          v-if="showAutoPlaceInput"
          class="filter"
          label="Place"
          :placeholder="placeFieldPlaceholder"
          :disabled="placeFieldDisabled"
          :value="simpleFormData.auto_place || {}"
          :show-default-options="false"
          :only-ancestral-places="false"
          :allow-free-input="true"
          multiselect-classes="bordered"
          label-classes="input-label"
          @select="onPlaceFieldSelect"
        ></place-input>

        <div class="filter small" v-if="showYearInput">
          <label for="record_date" class="input-label">Year</label>
          <div>
            <input
              id="record_date"
              :value="simpleFormData.record_year"
              placeholder="YYYY"
              class="input record-year"
              @keyup.enter="onInputEnter"
              @input="onInputChange('record_year', $event)"
            />
          </div>
        </div>
        <mcr-button class="search-button" :disabled="isLoading" @click="runSearch">
          <search-icon class="search-icon" :size="20"></search-icon>
          <span>Search</span>
        </mcr-button>
      </div>
      <div v-if="activeTab === TAB_ID_FULL_TEXT" class="full-text-form form">
        <div class="filter">
          <label class="input-label">First Name or Keyword</label>
          <input
            v-capitalize
            :value="simpleFormData.q"
            class="input"
            @input="onInputChange('q', $event)"
            @keyup.enter="onInputEnter"
          />
        </div>
        <div class="filter">
          <label class="input-label">Last Name</label>
          <input
            v-capitalize
            :value="simpleFormData.last_name"
            class="input"
            @input="onInputChange('last_name', $event)"
            @keyup.enter="onInputEnter"
          />
        </div>
        <mcr-button class="search-button" :disabled="isLoading" @click="runSearch">
          <search-icon class="search-icon" :size="20"></search-icon>
          <span>Search</span>
        </mcr-button>
      </div>
      <div v-if="activeTab === TAB_ID_SOURCES" class="sources-form form">
        <div class="filter">
          <label class="input-label">Keyword</label>
          <input
            v-capitalize
            :value="simpleFormData.q"
            class="input"
            @input="onInputChange('q', $event)"
            @keyup.enter="onInputEnter"
          />
        </div>
        <place-input
          class="filter"
          label="Place"
          :placeholder="placeFieldPlaceholder"
          :disabled="placeFieldDisabled"
          :value="simpleFormData.auto_place || {}"
          :show-default-options="false"
          :only-ancestral-places="false"
          multiselect-classes="bordered"
          label-classes="input-label"
          @select="onPlaceFieldSelect"
        ></place-input>
        <mcr-button class="search-button" :disabled="isLoading" @click="runSearch">
          <search-icon class="search-icon" :size="20"></search-icon>
          <span>Search</span>
        </mcr-button>
      </div>
    </div>
    <div class="form-display-container">
      <div class="search-data">
        <div class="text-sm">{{ totalCountDisplay }}</div>
        <div class="values text-md" v-if="simpleFormDataLabels.length">
          <div class="value" v-for="value in simpleFormDataLabels">{{ value }}</div>
        </div>
      </div>
      <div class="edit" @click="$emit('click-edit')"><pencil-icon :size="20"></pencil-icon></div>
    </div>
    <div class="meta-container" :class="{'no-tags': !otherAppliedFilters.length}">
      <div class="applied-filters-tags">
        <div
          v-for="appliedFilter in otherAppliedFilters"
          class="tag text-sm"
          @click="removeAppliedFilter(appliedFilter)"
        >
          <div class="type" v-if="appliedFilter.showType">{{ appliedFilter.type }}:</div>
          <div class="value">{{ appliedFilter.value }}</div>
          <div class="is-optional" v-if="appliedFilter.isOptional">(optional)</div>
          <close-icon :size="16"></close-icon>
        </div>
      </div>
      <div class="search-buttons" v-if="showSearchButtons">
        <div class="separator">|</div>
        <div class="clear-filters button text-sm" @click="clearAppliedFilters">Clear All</div>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import PlaceInput from '@common/elements/inputs/PlaceInput';
import {
  getClanName,
  getFactsForSearch,
  isRecordYearValid,
  isSourcesFormPlaceFieldDisabled,
} from '@common/pages/searches/helpers/utils';
import {
  HAS_IMAGES_OPTIONS,
  HAS_OCR_OPTIONS,
  TAB_ID_FULL_TEXT,
  TAB_ID_INDEXED_RECORDS,
  TAB_ID_SOURCES,
} from '@common/utils/consts.search';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import CloseIcon from 'vue-material-design-icons/Close';
import SearchIcon from 'vue-material-design-icons/Magnify';
import PencilIcon from 'vue-material-design-icons/Pencil';
import {mapGetters} from 'vuex';

const INDEXED_FORM_STATIC_KEYS = ['first_name', 'surname'];
const INDEXED_FORM_EXTRA_KEYS = ['auto_place', 'record_year'];
const INDEXED_FORM_KEYS = [...INDEXED_FORM_STATIC_KEYS, ...INDEXED_FORM_EXTRA_KEYS];
const FULL_TEXT_FORM_KEYS = ['q', 'last_name'];
const SOURCES_FORM_KEYS = ['q', 'auto_place'];

const ANCESTRAL_PLACE_FILTERS = ['ancestral_level_3', 'ancestral_level_2', 'ancestral_level_1', 'ancestral_place'];
const SKIP_OTHER_FILTERS = {
  [TAB_ID_INDEXED_RECORDS]: [...INDEXED_FORM_STATIC_KEYS, 'is_flexible'],
  [TAB_ID_FULL_TEXT]: [...FULL_TEXT_FORM_KEYS, ...ANCESTRAL_PLACE_FILTERS],
  [TAB_ID_SOURCES]: [...SOURCES_FORM_KEYS, ...ANCESTRAL_PLACE_FILTERS],
};

export default {
  props: {
    activeTab: String,
    options: Object,
    facets: Object,
    formData: Object,
    totalCount: Number,
    isLoading: Boolean,
  },
  watch: {
    formData: {
      handler: function (value) {
        this.simpleFormData = this.getSimpleFormData(value, this.activeTab);
      },
      deep: true,
    },
  },
  data() {
    return {
      TAB_ID_INDEXED_RECORDS,
      TAB_ID_FULL_TEXT,
      TAB_ID_SOURCES,
      simpleFormData: this.getSimpleFormData(this.formData, this.activeTab),
    };
  },
  computed: {
    ...mapGetters(['searchAllRecordsOptionsState', 'sourceDetailsState', 'cemeteryState']),
    placeFieldDisabled() {
      const mapping = {
        [TAB_ID_INDEXED_RECORDS]: this.placeFieldDisabledIndexed,
        [TAB_ID_FULL_TEXT]: this.placeFieldDisabledFullText,
        [TAB_ID_SOURCES]: this.placeFieldDisabledSources,
      };
      return mapping[this.activeTab];
    },
    placeFieldDisabledIndexed() {
      return false;
    },
    placeFieldDisabledFullText() {
      return false;
    },
    placeFieldDisabledSources() {
      if (this.activeTab === TAB_ID_SOURCES) {
        return isSourcesFormPlaceFieldDisabled(this.formData);
      }
      return false;
    },
    placeFieldPlaceholder() {
      if (this.placeFieldDisabled) {
        return 'Clear the place selections';
      }
      return 'All Places';
    },
    showYearInput() {
      return !this.formData.record_year;
    },
    showAutoPlaceInput() {
      return isEmpty(this.formData.auto_place);
    },
    otherAppliedFilters() {
      let keys = Object.keys(this.formData).filter(key => {
        if (
          INDEXED_FORM_EXTRA_KEYS.includes(key) &&
          !isEmpty(this.formData[key]) &&
          this.activeTab === TAB_ID_INDEXED_RECORDS
        ) {
          return true;
        }
        return isEmpty(this.simpleFormData[key]) && (this.formData[key] === true || !isEmpty(this.formData[key]));
      });
      let filters = [];
      for (let key of keys) {
        if (this.shouldIgnoreAppliedFilter(key)) {
          continue;
        }
        if (key === 'facts') {
          filters.push(...this.getOtherFiltersFromFacts(getFactsForSearch(this.formData)));
          continue;
        }
        if (key === 'relatives') {
          filters.push(...this.getOtherFiltersFromRelatives(this.formData.relatives));
          continue;
        }
        filters.push({
          type: this.getOtherFilterLabel(key),
          value: this.getOtherFilterValue(key, this.formData[key]),
          showType: this.getOtherFilterShowType(key),
          isOptional: this.getOtherFilterIsOptional(key),
          key,
        });
      }
      const ancestralPlaces = ANCESTRAL_PLACE_FILTERS.map(key => {
        return {
          type: 'Ancestral Place',
          value: this.getOtherFilterValue(key, this.formData[key]),
          key,
          showType: this.getOtherFilterShowType(key),
        };
      }).filter(value => !isEmpty(value.value));
      if (ancestralPlaces.length) {
        filters.push(ancestralPlaces[0]);
      }
      return filters;
    },
    simpleFormDataLabels() {
      if (this.activeTab === TAB_ID_INDEXED_RECORDS) {
        const fullName =
          this.simpleFormData.surname && this.simpleFormData.first_name
            ? `${this.simpleFormData.surname}, ${this.simpleFormData.first_name}`
            : this.simpleFormData.surname || this.simpleFormData.first_name;
        const place = this.getAutoPlaceDisplay(this.simpleFormData.auto_place);
        const year = this.simpleFormData.record_year;
        return [fullName, place, year].filter(v => !!v);
      }
      if (this.activeTab === TAB_ID_FULL_TEXT) {
        return [this.simpleFormData.q, this.simpleFormData.last_name].filter(v => !!v);
      }
      if (this.activeTab === TAB_ID_SOURCES) {
        const place = this.getAutoPlaceDisplay(this.simpleFormData.auto_place);
        return [this.simpleFormData.q, place].filter(v => !!v);
      }
      return [];
    },
    totalCountDisplay() {
      if (!this.totalCount) {
        return '';
      }
      const forStr = this.simpleFormDataLabels.length ? 'for' : '';
      const records = this.totalCount === 1 ? 'record' : 'records';
      return `${this.totalCount.toLocaleString('en')} ${records} ${forStr}`;
    },
    showSearchButtons() {
      return this.otherAppliedFilters.length;
    },
  },
  methods: {
    getSimpleFormData(formData, activeTab) {
      if (activeTab === TAB_ID_INDEXED_RECORDS) {
        return pick(formData, INDEXED_FORM_KEYS);
      }
      if (activeTab === TAB_ID_FULL_TEXT) {
        return pick(formData, FULL_TEXT_FORM_KEYS);
      }
      if (activeTab === TAB_ID_SOURCES) {
        return pick(formData, SOURCES_FORM_KEYS);
      }
      return {};
    },
    onInputChange(fieldName, event) {
      this.onValueChange(fieldName, event.target.value.trim());
    },
    onValueChange(fieldName, fieldValue) {
      this.$set(this.simpleFormData, fieldName, fieldValue);

      if (this.placeFieldDisabled) {
        this.$set(this.simpleFormData, 'auto_place', {});
      }
    },
    onPlaceFieldSelect(value) {
      this.onValueChange('auto_place', value);
    },
    onInputEnter() {
      this.runSearch();
    },
    shouldIgnoreAppliedFilter(key) {
      if (SKIP_OTHER_FILTERS[this.activeTab].includes(key)) {
        return true;
      }
      if (key === 'source_id' && this.formData[key] && this.sourceDetailsState.id !== this.formData[key]) {
        return true;
      }
      if (
        key === 'cemetery_id' &&
        this.formData[key] &&
        this.cemeteryState &&
        this.cemeteryState.object_id.toString() !== this.formData[key].toString()
      ) {
        return true;
      }
    },
    runSearch() {
      if (this.isLoading) {
        return;
      }
      const mapping = {
        [TAB_ID_INDEXED_RECORDS]: this.mutateIndexedForm,
        [TAB_ID_FULL_TEXT]: this.mutateFullTextForm,
        [TAB_ID_SOURCES]: this.mutateSourcesForm,
      };

      if (this.activeTab === TAB_ID_FULL_TEXT && this.simpleFormData.hasOwnProperty('q') && !this.simpleFormData.q) {
        this.$toasted.error('Please search a First Name or Keyword.');
        return;
      }
      if (this.activeTab === TAB_ID_INDEXED_RECORDS && !isRecordYearValid(this.simpleFormData.record_year)) {
        this.$toasted.error('Please input Year in a invalid format. Range is not supported.');
        return;
      }
      mapping[this.activeTab](this.simpleFormData);
      this.submit();
    },
    removeAppliedFilter(filter) {
      if (this.isLoading) {
        return;
      }
      const mapping = {
        [TAB_ID_INDEXED_RECORDS]: this.mutateIndexedForm,
        [TAB_ID_FULL_TEXT]: this.mutateFullTextForm,
        [TAB_ID_SOURCES]: this.mutateSourcesForm,
      };
      let newData = {};
      if (['relatives', 'facts'].includes(filter.key)) {
        newData = {[filter.key]: this.formData[filter.key].filter(item => item.formId !== filter.formId)};
      } else {
        newData = {[filter.key]: null};
      }

      mapping[this.activeTab](newData);
      this.submit();
    },
    clearAppliedFilters() {
      if (this.isLoading) {
        return;
      }
      const mapping = {
        [TAB_ID_INDEXED_RECORDS]: this.mutateIndexedForm,
        [TAB_ID_FULL_TEXT]: this.mutateFullTextForm,
        [TAB_ID_SOURCES]: this.mutateSourcesForm,
      };
      let newData = {};
      for (let filter of this.otherAppliedFilters) {
        newData[filter.key] = ['relatives', 'facts'].includes(filter.key) ? [] : null;
      }
      mapping[this.activeTab](newData);
      this.submit();
    },
    submit() {
      this.$emit('submit');
    },
    mutateIndexedForm(newData) {
      this.$store.commit('mutateSearchAllRecordsFormState', {...this.formData, ...newData});
    },
    mutateFullTextForm(newData) {
      this.$store.commit('mutateSourcesTextSearchFormState', {...this.formData, ...newData});
    },
    mutateSourcesForm(newData) {
      this.$store.commit('mutateSearchAllSourcesFormState', {...this.formData, ...newData});
    },
    getOtherFilterLabel(key) {
      const mapping = {
        record_year: 'Year',
        auto_place: 'Place',
        gender: 'Gender',
        birth_location: 'Birth place',
        death_location: 'Death place',
        birth_year: 'Birth year',
        death_year: 'Death year',
        residence_location: 'Residence',
        category_id: 'Category',
        source_types: 'Source type',
        source_type: 'Source type',
        source_location: 'Source place',
        source_id: 'Search within',
        cemetery_id: 'Search within',
        collection: 'Search within',
        ancestral_place: 'Ancestral place',
        ancestral_level_1: 'Ancestral place',
        ancestral_level_2: 'Ancestral place',
        ancestral_level_3: 'Ancestral place',
        clan_name: 'Clan name',
        facts: 'Facts',
        relatives: 'Relatives',
        has_images: 'Has images',
        record_format: 'Indexing state',
        publication_year: 'Publication year',
      };
      return mapping[key] || key;
    },
    getOtherFilterValue(key, value) {
      const mapping = {
        record_year: this.getRecordYearDisplay,
        residence_location: this.getAutoPlaceDisplay,
        gender: this.getGenderDisplay,
        auto_place: this.getAutoPlaceDisplay,
        birth_location: this.getAutoPlaceDisplay,
        death_location: this.getAutoPlaceDisplay,
        source_location: this.getAutoPlaceDisplay,
        ancestral_place: this.getAutoPlaceDisplay,
        ancestral_level_1: this.getAutoPlaceDisplay,
        ancestral_level_2: this.getAutoPlaceDisplay,
        ancestral_level_3: this.getAutoPlaceDisplay,
        clan_name: this.getClanNameDisplay,
        category_id: this.getCategoryDisplay,
        source_types: this.getSourceTypeDisplay,
        source_type: this.getSourceTypeDisplay,
        record_format: this.getRecordFormatDisplay,
        source_id: this.getSearchWithinSourceDisplay,
        collection: this.getSearchWithinCollectionDisplay,
        cemetery_id: this.getSearchWithinCemeteryDisplay,
        with_fuzzy_first_name: () => 'Similar First Names',
        with_fuzzy_surname: () => 'Similar Surnames',
        has_images: this.getHasImagesDisplay,
        has_ocr: this.getHasOcrDisplay,
      };
      const method = mapping[key];
      return method ? method(value) : value;
    },
    getOtherFilterShowType(key) {
      const valueOnly = ['with_fuzzy_first_name', 'with_fuzzy_surname', 'has_images', 'has_ocr'];
      return valueOnly.includes(key) ? false : true;
    },
    getOtherFilterIsOptional(key) {
      const flexible = ['birth_year', 'death_year', 'birth_location', 'death_location'];
      return flexible.includes(key) && this.formData.is_flexible;
    },
    getGenderDisplay(gender) {
      const genders = this.options && this.options.genders;
      const gen = genders ? genders.find(c => c.value == gender) : null;
      return (gen && gen.name) || 'Unknown';
    },
    getRecordYearDisplay(value) {
      if (typeof value === 'string' && value.includes('-')) {
        const year = value.split('-')[1];
        return `${year}s`;
      }
      return value;
    },
    getAutoPlaceDisplay(place) {
      return place && place.full_address_en;
    },
    getClanNameDisplay(clanName) {
      if (!this.facets || !this.facets.clans) return clanName;
      const clan = this.facets.clans.find(clan => clan.name_hant === clanName || clan.name_ch === clanName);
      return clan ? getClanName(clan) : clanName;
    },
    getCategoryDisplay(categoryId) {
      if (!this.facets) return categoryId;
      const allOptions = this.searchAllRecordsOptionsState;
      const categories = allOptions && allOptions.categories ? allOptions.categories : [];
      const category = categories.find(c => c.id === categoryId);
      return category ? category.name : categoryId;
    },
    getSourceTypeDisplay(sourceType) {
      let sourceTypes = this.facets && this.facets.source_type ? this.facets.source_type : [];
      let type = sourceTypes.find(st => st.object_id === sourceType);
      if (type && type.name) {
        return type.name;
      }
      const allOptions = this.searchAllRecordsOptionsState;
      sourceTypes = allOptions && allOptions.source_types ? allOptions.source_types : [];
      type = sourceTypes.find(st => st.value === sourceType);
      return type && type.name ? type.name : sourceType;
    },
    getRecordFormatDisplay(value) {
      const formats = this.facets && this.facets.record_format;
      const format = formats && formats.length ? formats.find(f => f.object_id === value) : null;
      return format ? format.name : value;
    },
    getSearchWithinSourceDisplay(value) {
      return this.sourceDetailsState.full_title || '';
    },
    getSearchWithinCollectionDisplay(value) {
      return (value && value.name) || '';
    },
    getSearchWithinCemeteryDisplay(value) {
      return this.cemeteryState ? this.cemeteryState.name : '';
    },
    getHasImagesDisplay(value) {
      return HAS_IMAGES_OPTIONS[value] || `Has images: ${value}`;
    },
    getHasOcrDisplay(value) {
      return HAS_OCR_OPTIONS[value] || `Full Text Available: ${value}`;
    },
    getOtherFiltersFromFacts(facts) {
      return facts.map(fact => {
        const values = [
          fact.value,
          fact.cemetery ? fact.cemetery.name : null,
          fact.place ? fact.place.address_en : null,
          fact.year,
        ].filter(v => !!v);
        const factType =
          fact.fact_type && this.options && this.options.facts
            ? this.options.facts.find(f => f.id === fact.fact_type)
            : null;
        const type = factType ? factType.label : fact.fact_category || fact.fact_type;
        return {
          type: type || 'Any event',
          value: values.join(', '),
          key: 'facts',
          formId: fact.formId,
          showType: this.getOtherFilterShowType('facts'),
        };
      });
    },
    getOtherFiltersFromRelatives(relatives) {
      return relatives.map(relative => {
        const fullName = `${relative.first_name || ''} ${relative.surname || ''}`.trim() || 'true';
        return {
          type: relative.relation_type,
          value: fullName,
          key: 'relatives',
          formId: relative.formId,
          showType: this.getOtherFilterShowType('relatives'),
        };
      });
    },
  },
  components: {PlaceInput, SearchIcon, CloseIcon, McrButton, PencilIcon},
  name: 'YourSearchForm',
};
</script>

<style scoped lang="scss">
.your-search-form {
  margin-bottom: 24px;
  padding: 16px 20px;
  border-radius: 4px;
  background: $background-light;

  .form-container {
    margin-bottom: 24px;
  }
  .form-container .form {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    align-items: flex-end;
    .filter {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-shrink: 1;
      input {
        width: 100%;
      }
    }
    .filter.small {
      width: fit-content;
      flex-grow: 0;
      flex-shrink: 0;

      input {
        width: 80px;
      }
    }
    .mcr-button {
      height: 44px;
      width: 114px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .form-display-container {
    display: none;
  }

  .meta-container {
    display: flex;
    align-items: flex-start;
    column-gap: 16px;

    .applied-filters-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      .tag {
        border-radius: 4px;
        padding: 6px 8px 6px 8px;
        display: flex;
        column-gap: 4px;
        background: $neutral-200;
        cursor: pointer;
        .type {
          white-space: nowrap;
          text-transform: capitalize;
        }
        .value {
          font-weight: 500;
        }
        .is-optional {
          color: $neutral-500;
        }
        .close-icon {
          margin-top: 2px;
          color: $neutral-500;
        }
      }
    }

    .search-buttons {
      flex-shrink: 0;
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
      .button {
        color: $neutral-500;
        cursor: pointer;
        padding: 8px 0;
      }
      .separator {
        color: $neutral-200;
        margin-right: 12px;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    margin-bottom: 16px;

    .form-container,
    .meta-container .search-buttons,
    .meta-container.no-tags {
      display: none;
    }
    .form-display-container {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      .edit {
        display: flex;
        color: $primary-400;
      }
      .search-data {
        display: flex;
        flex-direction: column;
        .text-sm {
          color: $neutral-600;
        }

        .values {
          color: $neutral-800;
          display: flex;
          flex-wrap: wrap;
          .value {
            font-weight: 500;
          }
          > .value:not(:last-child)::after {
            content: '•';
            margin: 0 4px;
            color: $neutral-400;
          }
        }
      }

      + .meta-container {
        margin-top: 20px;
      }
    }
  }
}
</style>
